<template>
  <price-card v-model="data" :configs="localConfigs"></price-card>
</template>

<script>
import cardMixins from "../mixins";
export default {
  mixins: [cardMixins],
  methods: {
    setPrice(configs) {
      const target = configs.find((config) => config.id === "price");
      if (!target) return;
      if (this.isUnitSame) {
        target.label = `單價`;
        target.value = this.value.price;
        target.valueSuffix = this.priceTextFunc({
          unit: this.shipping_unit,
          showPrice: false
        });
        return;
      }
      target.label = this.calculate_unit ? `單價(每${this.calculate_unit})` : '單價';
      target.value = this.value.price;
      target.valueSuffix = this.priceTextFunc({
        unit: this.calculate_unit,
        showPrice: false
      });
    },
  },
};
</script>